import { createTheme } from '@mui/material/styles';

const TifTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgb(255,255,255)'
    },
    secondary: {
      main: '#D2292D'
    },
    background: {
      default: '#181818',
      paper: 'rgba(24,24,24,0.85)'
    },
    text: {
      primary: 'rgb(255,255,255)'
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"lato", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase'
    },
    h2: {
      fontFamily: '"lato", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase'
    },
    h3: {
      fontFamily: '"lato", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase'
    },
    h4: {
      fontFamily: '"lato", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase'
    },
    h5: {
      fontFamily: '"lato", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase'
    },
    h6: {
      fontFamily: '"lato", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase'
    },
    subtitle1: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif'
    },
    subtitle2: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif'
    },
    body1: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif'
    },
    body2: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif'
    },
    button: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase'
    },
    caption: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: 'rgba(24, 24, 24, 0.85)',
          color: '#fff',
          backdropFilter: 'blur(5px)',
          backgroundImage: 'unset'
        }
      },
      defaultProps: {
        color: 'inherit'
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(3px)'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        colorInherit: {
          color: 'rgba(255,255,255,0.85)'
        }
      },
      defaultProps: {
        color: 'inherit'
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          '*::-webkit-scrollbar': {
            width: '0.4em'
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(68,68,68,1)',
            webkitBoxShadow: 'inset 0 0 6px rgba(68,68,68, 1)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#404040'
          }
        }
      }
    }
  }
});

export default TifTheme;
