import { InMemoryCache, makeVar } from '@apollo/client';
// import { concatPagination } from '@apollo/client/utilities';
import { TypedTypePolicies } from '../generated/apollo-helpers';

export const loggedInUserVAR = makeVar(null);
export const accessTokenVAR = makeVar(undefined); // access token to pass in apolloClient
export const isLoggedInVAR = makeVar<boolean>(false); // isloggedin vill always return false if user is not loggedin, gets updated by authuser
export const isAdminVAR = makeVar<boolean>(false);

export const catwalkIdVAR = makeVar(null); // id of a catwalk in RowData from table
export const seriesIdVAR = makeVar(''); // id of a series in RowData from seriesTable
export const seasonIdVAR = makeVar(''); // id of a season in RowData from seasonTable

export const titleVAR = makeVar('');
export const descriptionVAR = makeVar('');
export const errorVAR = makeVar(false);

export const categoryVAR = makeVar('');

export const playerCoverVAR = makeVar('');

export const uploadPageSwitcherVAR = makeVar('details'); // active button in add catwalk page

export const selectedAppBarItemVAR = makeVar(null); // item in AppBar to show as active

export const activeBrandSelectedVAR = makeVar(null);

export const toggleDrawerVAR = makeVar(true);

export const rowDataVAR = makeVar(null);
export const uploadPercentVAR = makeVar('noUpload');

const typePolicies: TypedTypePolicies = {
  // Keys in this object will be validated against the typed on your schema
  Query: {
    fields: {
      accessToken: {
        read() {
          return accessTokenVAR();
        }
      },
      setCategory: {
        read() {
          return categoryVAR();
        }
      },
      pageSwitcher: {
        read() {
          return uploadPageSwitcherVAR();
        }
      },
      loggedUser: {
        read() {
          return loggedInUserVAR();
        }
      },
      AppBarItem: {
        read() {
          return selectedAppBarItemVAR();
        }
      },
      catwalkTitle: {
        read() {
          return titleVAR();
        }
      },
      toggleDrawer: {
        read() {
          return toggleDrawerVAR();
        }
      },
      playerCover: {
        read() {
          return playerCoverVAR();
        }
      }
    }
  }
};

const cache = new InMemoryCache({
  typePolicies
});

export default cache;
