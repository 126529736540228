/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import createCache from '@emotion/cache';
import { ApolloProvider } from '@apollo/client';
// import { AppProps } from 'next/app';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { useRouter } from 'next/router';
import { useApollo } from '../util/apolloClient';
import TifTheme from '../theme/TifTheme';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-image-crop/dist/ReactCrop.css';
import { selectedAppBarItemVAR } from '../util/cache';

export const cache = createCache({ key: 'css', prepend: true });

export default function App({ Component, pageProps }) {
  const router = useRouter();
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  React.useEffect(() => {
    const activeItem = router.pathname.split('/').pop();
    selectedAppBarItemVAR(activeItem);
  }, [router.pathname]);

  const apolloClient = useApollo(pageProps.initialApolloState);
  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cache}>
        <ThemeProvider theme={TifTheme}>
          <ApolloProvider client={apolloClient}>
            <CssBaseline />
            <Component {...pageProps} />
          </ApolloProvider>
        </ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );
}
