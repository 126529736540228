/* eslint-disable  default-case */
/* eslint-disable  no-loop-func */
/* eslint-disable  consistent-return */

import { useMemo } from 'react';
import {
  ApolloClient,
  HttpLink,
  split,
  NormalizedCacheObject,
  gql,
  // fromPromise,
  ApolloLink
} from '@apollo/client';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import Cookies from 'universal-cookie';
import cache, { accessTokenVAR, isLoggedInVAR, loggedInUserVAR } from './cache';

const cookies = new Cookies();
// const refreshToken = cookies.get('refreshToken');
const url = process.env.HASURA_URL;
const jwtToken: any = () => {
  if (accessTokenVAR()) {
    return jwtDecode<JwtPayload>(accessTokenVAR());
  }
  return null;
};

export const ACCESS_TOKEN = gql`
  query GetAccessToken {
    accessToken @client
  }
`;

const queryToken = `
mutation refreshToken($refresh_token: String!) {
  action_refresh_token(refresh_token: $refresh_token) {
    refreshToken
    token
  }
}`;

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const getNewToken = async () => {
  try {
    const newToken = await apolloClient.query({ query: ACCESS_TOKEN }).then((response) => {
      const { accessToken } = response.data;
      return accessToken;
    });
    return accessTokenVAR(newToken);
  } catch (err) {
    return console.log('Token fetch error in apollo: ', err); // eslint-disable-line
  }
};

const httpLink = new HttpLink({
  uri: process.env.HASURA_URL,
  credentials: 'same-origin'
});

const websocketUrl = new URL(process.env.HASURA_URL);

const wsLink =
  typeof window !== 'undefined'
    ? new WebSocketLink({
        uri: `wss://${websocketUrl.host}${websocketUrl.pathname}`,
        options: {
          lazy: true,
          reconnect: true,
          connectionParams: async () => {
            await getNewToken();
            return {
              headers: {
                ...(accessTokenVAR() !== undefined && {
                  Authorization: `Bearer ${accessTokenVAR()}`
                })
              }
            };
          }
        }
      })
    : null;
// eslint-disable-next-line
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      'content-type': 'application/json',
      // Authorization: `Bearer ${accessTokenVAR()}`
      ...headers,
      ...(accessTokenVAR() !== undefined && { Authorization: `Bearer ${accessTokenVAR()}` })
    }
  };
});

const splitLink =
  typeof window !== 'undefined'
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        wsLink,
        authLink.concat(httpLink)
      )
    : httpLink;

const link = ApolloLink.from([
  new TokenRefreshLink({
    // Checks if token is valid.
    isTokenValidOrUndefined: () => {
      if (!cookies.get('refreshToken')) {
        window.location.replace(process.env.FRONTEND_WEB_URL); // If no refresh token is present navigate to fr web
      } else {
        const expired =
          (new Date(jwtToken()?.exp).getTime() - Date.parse(new Date().toString()) / 1000) / 60;
        return expired > 60 || typeof cookies.get('refreshToken') !== 'string';
      }
      return null;
    },
    fetchAccessToken: async () => {
      // console.log('fetch token cookie: ', cookies.get('refreshToken'));
      const accessToken = await fetch(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          query: queryToken,
          variables: {
            refresh_token: cookies.get('refreshToken')
          }
        })
      })
        .then((res) => {
          if (res.status === 404) {
            const error = new Error('authorization failed');
            console.log(error.message); // eslint-disable-line
          }

          return res;
        })
        .then((res) => res.json());
      return accessToken?.data?.action_refresh_token?.token;
    },
    handleFetch: () => {},
    handleResponse: () => (response: any) => {
      accessTokenVAR(response);
      const loggedUser = jwtDecode<JwtPayload>(response);
      loggedInUserVAR(loggedUser);
      isLoggedInVAR(true);

      if (loggedUser && !jwtToken()?.roles?.toString().includes('admin')) {
        return window.location.replace(process.env.FRONTEND_WEB_URL);
      }
    },
    handleError: (err) => {
      // full control over handling token fetch Error

      if (err.name === 'TypeError') {
        // this error is expected due to bug https://github.com/newsiberian/apollo-link-token-refresh/issues/24

        return err;
      }
      // your custom action here

      isLoggedInVAR(false);
      loggedInUserVAR(null);

      throw err;
    }
  }) as any,
  authLink,
  splitLink
]);

// This should only add the header if value is not undefined
// this will always set the header even when emty val is there
// Reactive Vars are made here and are implemented in fields in cache below

function createApolloClient() {
  return new ApolloClient({
    cache,
    ssrMode: typeof window === 'undefined',
    link,
    connectToDevTools: true
  });
}

export function initializeApollo(initialState: any = null) {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
